#about {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 800px;
    margin: 50px auto 0 auto;
}
#about p {
    font-size:17px ;
    margin: 0 0 15px 0;
}
#about h3, #about h2 {
    margin-bottom: 8px;
    color: #3a66b1 ;
}
#aboutLogo {
    width: 13vw;
    height: 4vw;
    object-fit: cover;
}
#fusLogo {
    width: 13vw;
    height: 4vw;
    margin-top: 70px;
}
@media screen and (max-width:920px) {
    #about {
        align-items: center;
        padding: 0 3vw;
    }
    #aboutLogo, #fusLogo {
        width: 50vw;
        height: 16vw;
    }
}