header {
    width: 99vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 15px 0 15px 0;
    height: 65.66px;
    transition: 0.4s all ease-in;
}
.expanded {
    height: 230px;
}
#headLogo, #headLogo2 {
    height: 65px;
    width: 10.41vw;
    cursor: pointer;
    object-fit: contain;
}
#headLogo2 {
    margin-left: 1.3vw;
    cursor: auto !important;
}
#headerShell {
    min-width: 1500px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
#headLinks {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    height: 40.66px;
    border: solid 0px #e087a2;
    transition: border-width 0.6s linear;
}
.headLink:hover {
    transition: all 0.4s ease-out;
    color: #11449b;
    border-bottom: 1px solid #11449b;
    height: 100%;
}
.headLink {
    margin-left: 1.5vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 65.66px;
}
#downHeader {
    width: 100vw;
    height: 160px;
    background-color: rgb(242, 242, 242);
    margin-top: 3px;
}
#headLinks img {
    display: none;
    margin-left: 1.5vw;
}
.cross {
    width: 30px;
    height: 30px;
    display: inline-block !important;
    cursor: pointer;
}
#downWrapper {
    z-index: -1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1570px;
    padding:0 0 20px 0 ;
    margin: 0 auto 0 auto;
    background-color: white;
    height: 160px;
}
.headRow {
    display: flex;
    flex-direction: column;
    margin-left: 2vw;
    margin-right: 2vw;
    align-items: flex-start;
}
.headRow h3 {
    color: #3a66b1;
    font-weight: normal;
    margin-bottom: 8px;
    font-size: 1vw;
}
.productItem{
    margin-bottom: 4px;
    cursor: pointer;
    font-size: 0.67vw;
}
.productItem:hover {
    color: #3a66b1;
}
.technicalWrap {
    justify-content: flex-start !important;
} 
.technicalWrap .headrow  {
    margin-left: 2vw !important;
}
@media screen and (max-width:1555px) {
    #headerShell {
        min-width: 0px;
        width: 100%;
    }
    header {
        width: 100vw;
        padding-right: 2vw;
        padding-left: 2vw;
    }
    #downWrapper {
        min-width: 0px !important;
        width: 100vw;
        padding:  0 2vw 0 2vw !important;
    }
    .cross {
        right: 10px;
    }
    .headRow {
        margin-left: 0;
    }
    .headRow h3 {
        font-size: 1.3vw;
    }
    .productItem{
        font-size: 1vw;
    }
    .headLink {
        font-size: 1.2vw;
    }
}
@media screen and (max-width:920px) {
    #headLogo {
        width: 15vw;
        height: 5vw;
    }
    .headLink {
        font-size: 1.9vw !important;
        text-align: center;
    }
    #logosWrapper {
        display: flex;
        align-items: center;
    }
    #logosWrapper a {
        display: flex;
        align-items: center;
    }
    #headLogo2 {
        width: 8.5vw;
        height: 5vw;
        object-fit: fill;
    }
    #headLinks div{
        font-size: 1.8vw;
        font-weight: 500;
        padding: 0 0 8px 0;
    }
    #downHeader {
        height: 21vw;
    }
    #headerShell {
        height: 77px;
    }
    .cross {
        height: 5vw;
        width: 5vw;
    }
    .expanded {
        height: 101vh;
        position: fixed;
        z-index: 5;
        background-color: white;
        top: -17px;
    }
    .headLink {
        background-color: white;
        height: auto;
        margin: 0 !important;
    }
    header {
        transition: none;
        height: 43px;
    }
    #downWrapper {
        justify-content: space-between;
        padding: 0 1.5vw !important;
        padding-bottom: 5vw !important;
    }
    .headRow {
        margin-right: 0;
        align-items: center;
    }
    .headRow h3 {
        font-size: 2vw;
        margin-bottom: 1vw;
    }
    .headRow span {
        font-size: 1.3vw;
    }
    .technicalWrap {
        justify-content: flex-start !important;
    } 
    .technicalWrap .headRow {
        margin-right: 2vw;
    }
    #headLogo2 {
        margin-left: 1.3vw;
    }
}
@media screen and (max-width:500px) {
    .headLink {
        font-size: 2.4vw !important;
    }
}