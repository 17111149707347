#contact {
    max-width: 1500px;
    margin: 0 auto 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
#contact h1 {
    color: gray;
    font-size: 40px;
    margin-bottom: 60px;
}
#contactText {
    border-bottom: 1px solid #3a66b1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 1vw 0 1vw;
}
.contactColumn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 20vw;
    min-width: 350px;
    margin-top: 3vw;
}
.contactColumn h3 {
    color: #3a66b1;
    text-align: left;
    margin: 0;
    font-size: 24px;
}
.contactColumn p {
    margin: 0.35vw 0 0 0;
    text-align: left;
}
.contactColumn a {
    color: #3a66b1;
    font-size: 25px;
    text-decoration: none;
}
#contactColWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 75vw;
}
.tel::before {
    content: " ";
    width: 17px;
    display: inline-block;
    margin-right: 10px;
    height: 17px;
    background-image: url("../media/phone.png");
    background-size: cover;
}
.mail::before {
    content: " ";
    width: 17px;
    display: inline-block;
    margin-right: 10px;
    height: 17px;
    background-image: url("../media/mail.png");
    background-position: center;
    background-size: 150%;
}
.contactColumnLast {
    min-width: 600px;
}
.contactForm {
    margin-top: 80px;
}
.contactInput {
    width: 99% !important;
    height: 3.5vw !important;
}
.contactFormCol {
    width: 33%;
}
#contactColWrap table {
    margin-bottom: 130px;
}
#contactTable tr:nth-child(1) {
    background-color: #787a80;
    color: white;
}
#contactTable td:nth-child(1) {
    width: 40vw;
}
@media screen and (max-width:920px) {
    #contact {
        align-items: center;
        max-height: 20000000px;
    }
    #contact h1 {
        font-size: 10vw;
    }
    #contactText {
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    #contactColWrap {
        width: 100vw;
        height: auto;
        align-items: center;
    }
    .contactColumn {
        width: 100vw;
        margin-top: 10vw;
        padding: 0 3vw;
        min-width: 0;
    }
    .contactColumnLast {
        min-width: 0;
    }
    .contactInput {
        width: 99vw;
        height: 10vw !important;
        padding: 0;
    }
    .contactFormCol {
        width: 99vw;
    }
    .formColumn * {
        text-align: center;
    }
}