#owners {
    max-width: 1300px;
    margin: 5vw auto 3vw auto;
    padding: 0 1vw 0 1vw;
}
#ownerPhoto {
    height: 20vw;
    width: 15vw;
    object-fit: cover;
    margin-right: 2vw;
}
#headOwner {
    display: flex;
    align-items: center;
}
#ownerText {
    display: flex;
    flex-direction: column;
    margin: 5vw auto 3vw auto;
    letter-spacing: 1px;
    line-height: 20px;
}
#icom {
    width: 8vw;
    height: 3.2vw;
    object-fit: contain;
    margin-top: 1vw;
}

@media screen and (max-width:920px) {
    #ownerPhoto {
        width: 35vw;
        height: 50vw;
    }
    #headOwner {
        flex-direction: column;
    }
    #headOwnerText {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    #icom {
        width: 40vw;
        height: 13vw;
        margin-top: 20px;
    }
    #ownerText {
        padding-left: 3vw;
    }
    #headOwnerText {
        padding-left: 3vw;
    }
}