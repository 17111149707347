#partnerSec {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 120px;
}
#partnerSec h1 {
    color: gray;
    font-size: 30px;
    margin-bottom: 60px;
    text-align: center;
}
#partnerSec iframe, #contact iframe {
    width: auto !important;
    height: auto !important;
}
.formColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
}
#partnerForm, #termsWrapper {
    min-width: 1500px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.formColumn input, select {
    border: 1px solid grey;
    width: 18vw;
    height: 3.2vw;
    margin-bottom: 0.8vw;
    -webkit-box-shadow: inset 0px 0px 13px 1px rgba(0,0,0,0.15); 
    box-shadow: inset 0px 0px 7px 1px rgba(0,0,0,0.15);
    font-size: 16px;
    padding: 5px 5px 5px 10px;
}
#send {
    background-color: #8db3f6;
    color: white;
    font-size: 20px;
    border-radius: 4px;
    transition: 0.5s all ease-in;
    cursor: pointer;
}
#partnerSend {
    background-color: #8db3f6;
    border: none;
    width: 18vw;
    color: white;
    font-size: 20px;
    cursor: pointer;
    border-radius: 4px;
    transition: 0.5s all ease-in;
}
.activeSend {
    background-color: #3a66b1 !important;
}
label {
    cursor: pointer;
}
#termsWrapper {
    margin: 1vw 0 !important;
} 
#terms {
    border: none;
}
.error {
    border: 3px solid rgb(242, 96, 96) !important;
    color: red;
}
.succesfulBut {
    background-color: rgba(44, 184, 44, 0.6) !important;
}
@media screen and (max-width:1555px) {
    #partnerForm, #termsWrapper {
        min-width: 0px;
        width: 98vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .formColumn input, select {
        width: 22.5vw;
        height: 5vw;
    }
    #bigInp {
        height: 10.8vw;
    }
    label {
        font-size: 1.2vw;
    }
    #partnerSend {
        width: 22.5vw;
    }
}
@media screen and (max-width:920px) {
    #partnerForm {
        flex-direction: column;
    }
    .formColumn * {
        width: 98vw !important;
        height: 10vw !important;
    }
    label {
        font-size: 2.5vw;
    }
}