body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  overflow-x: hidden;
}
html:root {
  --swiper-theme-color: #3a66b1 !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500&display=swap');
* {
  font-family: 'Ubuntu', sans-serif;
  box-sizing: border-box;
}
.banners {
  width: 100vw;
  height: 31.25vw;
  transition: 1s all ease-in;
  object-fit: cover;
  filter: brightness(70%);
  margin-bottom: 3vw;
}
@media screen and (max-width:750px) {
    .banners {
        height: 50vw;
    }
}