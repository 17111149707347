#maintanance {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 6vw;
    max-width: 1000px;
    margin: 0 auto;
}
#bigLogo {
    width: 18.5vw;
    height: 6vw;
    object-fit: cover;
}
#tableImg {
    width: 1000px;
    height: 420px;
    margin: 0 auto;
}
#maintanance  p {
    margin: 10px 0 0 0;
}
#maintanance #firstMaintTable p {
    margin: 10px 0 0 0;
    font-size: 17px;
    height: 46px;
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid black;
}
#maintanance h2 {
    margin-top: 5vw;
}
#firstMaintTable {
    width: 100%;
}
#maintanance h3 {
    margin-top: 5vw;
    width: 55vw;
    text-align: left;
}
.maintext {
    font-size: 18px;
}
#table2Img {
    width: 55vw;
    height: 33vw;
}
#table3Img {
    width: 55vw; height:28vw;
    margin-top: 1vw;
}
.maintTable {
    margin-bottom: 4vw;
    width: 1000px;
}
.maintTable td {
    border:none;
}
.maintTable tr:nth-child(2n) {
    background-color: white;
}
.maintTable tbody tr:nth-child(1) {
    font-weight: 600;
    background-color: white;
}
.maintTable tbody tr td:nth-child(1) {
    width: 30vw;
}
#lastMaintRow {
    margin-bottom: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
#firstMaintTable tbody tr td:nth-child(2) {
    width: 600px
}
#firstMaintTable tbody tr td:nth-child(1),
#firstMaintTable tbody tr td:nth-child(3) {
    width: 200px;
}
#firstMaintTable tbody tr:nth-child(1) {
    background-color: #555;
    color: white;
}
#firstMaintTable {
    margin-bottom: 20px;
}
@media screen and (max-width:920px) {
    #tableImg {
        width: 95vw;
        height: 45vw;
    }
    .maintTable {
        max-width: 95vw;
    }
    .maintTable * {
        text-align: left !important;
    }
    #maintanance h3 {
        text-align: center !important;
    }
    #maintanance h2 {
        margin-top: 20vw;
    }
    #maintanance p {
        margin-top: 0;
    }

    #bigLogo {
        width: 50vw;
        height: 16vw;
        margin-bottom: 3vw;
    }
    #firstMaintTable p {
        margin-bottom: 4vw;
        font-size: 13px;
    }
}
@media screen and (max-width:1000px) {
    #maintanance {
        min-width: 0 ;
        width: 95vw;
    }
    .maintext {
        width: 95vw;
    }
    #tableImg {
        width: 95vw;
        height: 45vw;
    }
    .maintTable {
        width: 95vw;
    }
}
@media screen and (max-width:500px) {
    #firstMaintTable p {
        margin-bottom: 4vw;
        font-size: 12px !important;
    }
}