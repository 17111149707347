#specs {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 80vh;
    background-size: cover;
    background-repeat: no-repeat;
}
#specs h1 {
    margin-top: 100px;
}
.secDoc {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.secDoc h3 {
    color: #3a66b1;
    font-size: 24px;
}
.secDoc a {
    font-size: 20px;
    color: black;
}
#docWrapper {
    min-width: 1555px;
    max-height: 80vw;
    padding: 1vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
@media screen and (max-width:1555px) {
    #docWrapper {
        min-width: 0px;
        width: 98vw;
    }
    .secDoc h3 {
        text-align: center;
        font-size: 1.4vw;
    }
    .secDoc span a {
        text-align: center;
        font-size: 1.2vw;
    }
}
@media screen and (max-width:920px) {
    #specs h1{
        font-size: 8vw;
        text-align: center;
    }
    #docWrapper {
        flex-direction: column;
        max-height: none;
    }
    .secDoc h3 {
        font-size: 6vw;
    }
    .secDoc span a {
        font-size: 4vw;
    }
}