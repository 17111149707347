#banner {
    width: 100vw;
    height: 31.25vw;
    background-image: url("../../src/media/mainpage.png");
    background-size: 160%;
    cursor: pointer;
    transition: 1s all ease-in;
    background-position:center;
}
#banner:hover {
    background-size: 165%;
}
.slide {
    display: flex;
    justify-content: center;
}
.smallBanner1 {background-image: url("../media/banner1.jpg");}
.smallBanner2 {background-image: url("../media/banner2.jpg");}
.smallBanner3 {background-image: url("../media/banner3.jpg");}
.smallBanner4 {background-image: url("../media/banner4.jpg");}
.smallBanner {
    height: 31.25vw;
    width: 75vw;
    cursor: pointer;
    background-size: cover;
}
.swiper-button-next {
    right:20.83vw !important;
}
.swiper-button-prev {
    left: 20.83vw !important;
}
#story {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 75vw;
    margin: 150px auto 0 auto;
}
#story h1 {
    font-size: 40px;
}
#storyBlocks {
    height: 24vw;
}
#storyBlocks,.storyImgs,#storyImgs {
    display: flex;
    
}
.successBut {
    width: 12vw;
    height: 33.333%;
    background-size: 3vw;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #e3e3e3;
    cursor: pointer;
    transition: 0.2s all ease-in;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 1vw;
    padding-bottom: 10px;
}
.successBut:hover {
    background-color: white;
    border-bottom: 4px solid red;
}
#butBlock .successBut:nth-child(1) {
    background-image: url("../media/but1.png");
}
#butBlock .successBut:nth-child(2) {
    background-image: url("../media/but2.png");
}
#butBlock .successBut:nth-child(3) {
    background-image: url("../media/but3.png");
}
#fstoryImg {
    background-image: url("../media/success1.jpg");
    width: 20vw;
    height: 100%;
    margin-left: 15px;
}
#fstoryImg h2, #secstoryImg h2 {
    max-height: 2vw;
    text-decoration: underline;
    min-height: 32px;
}
#secstoryImg {
    background-image: url("../media/success2.jpg");
    width: 40vw;
    height: 100%;
    margin-left: 15px;
}
#secstoryImg, #fstoryImg {
    transition: 0.75s all ease-in;
    padding: 1vw;
    display: flex;
    align-items: flex-end;
    color: white;
    font-size:0.6vw;
    cursor: pointer;
    background-size: cover;
}
#fstoryImg:hover {
    width: 40vw;
    box-shadow: inset 0px 0px 50px 50px rgba(0,0,0,0.17);
}
#fstoryImg:hover + #secstoryImg{
    width: 20vw;
}
#secstoryImg:hover {
    width: 40vw;
    box-shadow: inset 0px 0px 50px 50px rgba(0,0,0,0.17);
}
#secstoryImg:hover + #fstoryImg{
    width: 20vw;
}
#learnMore {
    margin-left: auto;
    margin-right: auto;
    margin-top: 80px;
    background-color: white;
    color: #3a66b1;
    width: 190px;
    height: 50px;
    border: 1px solid #3a66b1;
    font-size: 15px;
    cursor: pointer;
}
#learnMoreMain {
    margin-left: calc(50vw - 95px);
    margin-top: 80px;
    background-color: white;
    color: #3a66b1;
    width: 190px;
    height: 50px;
    border: 1px solid #3a66b1;
    font-size: 15px;
    cursor: pointer;
}
#learnMore:hover, #learnMoreMain:hover {
    background-color: #3a66b1;
    color: white;
}
@media screen and (max-width:920px) {
    .smallBanner {
        background-size: 150%;
        background-position: center;
        width: 100vw !important;
        height: 40vw !important;
    }
    .swiper-button-next, .swiper-button-prev {
        display: none !important;
    }
    #story h1 {
        font-size: 5vw;
    }
    #storyBlocks {
        height: 60vw;
    }
    #secstoryImg, #fstoryImg {
        font-size: 1.3vw;
        align-items: center;
        margin-left: 1vw;
    }
    #secstoryImg {
        width: 57vw;;
    }
    #carousel {
        margin-top: 100px;
    }
    #banner {
        height: 50vw;
        background-repeat: no-repeat;
        background-size: 150%;
    }
    .successBut {
        width: 16vw;
        font-size: 2.2vw;
        text-align: center;
        background-size: 50%;
        background-position: 50% 20%;
    }
    #fstoryImg h2, #secstoryImg h2 {
        font-size: 2.5vw;
    }
    #downWrapper {
        flex-direction: column;
        height: auto;
    }
    .headRow h3 {
        font-size: 4.5vw;
    }
    .headRow span {
        font-size: 3vw;
    }
}
